<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title"> Information</h4>
                        <br>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Site Name :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.site_name}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Region :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.region.name}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Location :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.location}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Platform Type :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.platform_type}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">District :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.district}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Enrollment status :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.enrollment_status}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Contact Person :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.contact_person}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Telephone :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.telephone}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Email :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.email}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Facility e-mail :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.facility_email}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Type of facility :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.type_facility}}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Site Category :</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.site_category}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-8">
                                <div class="d-inline-flex">
                                    <div class="mr-3 align-self-center h6">Comment</div>
                                    <div class="mr-3 align-self-center h6"> {{this.siteData.comments}}</div>
                                </div>
                            </div>
                        </div>

                        <div style="display: flex; gap: 1rem;">
                            <button class="btn btn-primary mt-4" @click.prevent="edit()">Edit</button>
                            <!--<button class="btn btn-primary mt-4 " @click.prevent="edit()">Edit</button>-->
                            <!--<button class="btn btn-success mt-4" @click="download()">Export Data</button>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex; justify-content: space-around" v-show="false">
            <vue-html2pdf
                    :show-layout="true"
                    :float-layout="false"
                    :paginate-elements-by-height="1400"
                    :enable-download="true"
                    :preview-modal="false"
                    :filename="siteData.site_name"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    :margin="0"
                    pdf-content-width="670px"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    ref="html2Pdf"

                    @progress="onProgress($event)"
                    @startPagination="startPagination()"
                    @hasPaginated="hasPaginated()"
                    @beforeDownload="beforeDownload($event)"
                    @hasDownloaded="hasDownloaded($event)"
            >
                <div class="body" slot="pdf-content">
                    <div class="container">
                        <div class="container_header">
                            <h5><b>Point-of-Care Technology Continuous Quality Improvement
                                For Early <br>Infant Diagnosis Program
                            </b></h5>
                            <h5>{{user.country_name}} ({{user.country_prefix}})</h5>
                        </div>
                        <!--<v-table>
                            <thead>
                                <tr>
                                    <th colspan="4">Participant Result Summary (Résumé des résultats du site) – </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colspan="2" style="margin: 1rem">Site Name:</th>
                                    <th style="margin: 1rem">Date PT Received</th>
                                    <td style="margin: 1rem">Date PT Received</td>
                                </tr>
                                <tr>
                                    <th colspan="2">{{siteData.site_name}}</th>
                                    <th>Date PT Received</th>
                                    <td>Date PT Received</td>
                                </tr>
                                <tr>
                                    <th>Site ID</th>
                                    <th>Instrument</th>
                                    <th>Reagent Lot No:</th>
                                    <td>Date PT Received</td>
                                </tr>
                                <tr>
                                    <th>Site ID</th>
                                    <th>Instrument</th>
                                    <th>Reagent Lot No:</th>
                                    <td>Date PT Received</td>
                                </tr>
                            </tbody>
                        </v-table>-->
                        <div class="thead-1" style="border: 1px solid; border-collapse: collapse">
                            <h6 style="padding: 5px">Participant Result Summary (Résumé des résultats du site) –</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="border-right: 1px solid; padding: 5px; "><h6>Site Name</h6></div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Date PT Received</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">Date PT Received</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="padding: 5px; border-right: 1px solid;"><h6>{{siteData.site_name}}</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Date PT Results Reported</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">Date PT Results Reported</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Site ID</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Instrument</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Reagent Lot No:</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">Reagent Lot No:</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;">{{siteData.ptid}}</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; ">Instrument</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Expiry Date</h6></div>
                                <div class="col-lg-3">Reagent Lot No:</div>
                            </div>
                        </div>
                        <div class="thead-2">
                            <h6>Site Result Summary/ résultat du site</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around;">
                                <div class="col-lg-3" style="color: white"><h6></h6>Expected Result</div>
                                <div class="col-lg-2" style="border-left: 1px solid;border-right: 1px solid;border-top: 1px solid; padding: 5px;"><h6>Site Name</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>Date PT Received</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>Score</h6></div>
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid; border-top: 1px solid;"><h6>Score</h6></div>
                            </div>
                            <div style="display: flex; flex-direction: column; text-align: center; justify-content: space-around; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex; text-align: center; justify-content: space-around;">
                                    <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;"><h6>Expected Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;"><h6>Site Name</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;"><h6>Date PT Results Reported</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">Date PT Results Reported</div>
                                    <div class="col-lg-3" style="padding: 5px;">Date PT Results Reported</div>
                                </div>
                                <div style="display: flex; text-align: center; justify-content: space-around; border-collapse: collapse">
                                    <div class="col-lg-3" style="border-right: 1px solid;border-top: 1px solid; "><h6>Your Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; "><h6>Instrument</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; "><h6>Reagent Lot No:</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">Reagent Lot No:</div>
                                    <div class="col-lg-3" style="color: white">Reagent Lot No:</div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-3">
                            <h6>Summary of All Site Scores/ Résumé des résultats de tous les sites</h6>
                            <div style="display: flex; flex-direction: column; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex;">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Participating Sites</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring below 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6><b>Percentage of Sites Scoring 100%</b></h6></div>
                                </div>
                                <div  style="display: flex; border-top: 1px solid">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Summary of All Site Scores/ Résumé des résultats de tous les sites</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Total number of Sites Scoring 100%</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Total number of Sites Scoring below 100%</h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6>Percentage of Sites Scoring 100%</h6></div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-4">
                            <h6>Percentage of all Participating Sites reporting correctly</h6>
                            <h6>Pourcentage de tous les sites participants qui établissent correctement leurs rapports</h6>
                            <div style="display: flex">
                                <div class="col-lg-3" style="color: white">Correctly Reported</div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid; border-left: 1px solid;border-right: 1px solid;"><h6><b>A</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>A</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>A</b></h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>Average</b></h6></div>
                            </div>
                            <div style="display: flex; border: 1px solid;">
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid;"><b>Correctly Reported</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>V</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>V</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>V</b></div>
                                <div class="col-lg-3" style="padding: 5px;"> <b>V</b></div>
                            </div>
                        </div>
                        <div class="thead-5">
                            <h5>OPERATOR’S NAME</h5>
                            <h5>Name</h5>
                        </div>

                    </div>
                </div>
            </vue-html2pdf>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {axios_get} from "../../../helpers/helper";


    /**
     * Form validation component
     */
    export default {
        page: {
            title: "View VLIS",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader},
        data() {
            return {
                user: JSON.parse(localStorage.getItem('user')).country,
                siteData: [],
                title: "View VLIS",
                items: [
                    {
                        text: "All VL Sites",
                        href: "/sites/vl"
                    },
                    {
                        text: "View VLIS",
                        active: true
                    }
                ],
            }
        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            console.log(this.id),
                axios_get("/sites/"+this.id,{},(d)=>{
                        console.log(d);
                        this.siteData=d[0];
                        this.siteData.enrollment_status= new Date(this.siteData.enrollment_status).getFullYear();
                        console.log(this.siteData);
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );
        },
        methods: {
            download(){
                console.log("dowload")
                this.$refs['html2Pdf'].generatePdf()
            },

            async beforeDownload ({ html2pdf, options=this.setOptions, pdfContent }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    console.log(pdf)
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        //pdf.setPage(i)
                        //pdf.setFontSize(10)
                        //pdf.setTextColor(150)
                        //pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    }
                }).save()
            },
            edit(){
                window.location.href = '/site/vl/edit-site/'+this.siteData.id;
            }
        }
    }
</script>

<style scoped>
    .container_header{
        text-align: center;
    }
    tbody, thead{
        width: 500px;
    }
    tr{
        text-align: center;
        font-size: 18px;
        margin: 1rem;
    }
    .thead-1,.thead-2,.thead-3,.thead-4,.thead-5{
        margin-top: 2rem;
        margin-bottom: 1rem;
        text-align: center;
    }
</style>